<script setup>
import AuthLoginForm from "@/features/auth/AuthLoginForm.vue";
import { ref, reactive, computed, inject, onMounted } from "vue";
import { message as antMessage } from "ant-design-vue";
import { useAuthStore } from "@/stores/auth.store";
import { useHttps } from "@/plugins/http";
import { useRouter } from "vue-router";

const onLoginEvent = inject("onLoginEvent");

const authStore = useAuthStore();
const https = useHttps();
const router = useRouter();

const verificationModal = ref(null);
const authLoginForm = ref(null);
const privacyContent = ref(null);
const authState = computed(() => ({
  token: authStore.getToken,
  fcmToken: authStore.getFcmToken,
  isAuthenticated: authStore.getIsAuthenticated,
}));

const formState = reactive({
  pending: false,
  username: "",
  password: "",
  loading: false,
  captchaVisible: false,
});
const verificationState = reactive({
  visible: false,
  loading: false,
  code: null,
  token: null,
  title: "Doğrulama Kodu",
  text: null,
  tryCount: 0,
  isGoogle2FA: false,
});

const refreshVerification = () => {
  verificationState.code = null;
  if (verificationModal.value) {
    verificationModal.value.restart();
  }
};

const loginSuccess = (response) => {
  onLoginEvent(response);
  antMessage.success("Giriş başarılı", 2);
  router.push("/chats");
};

const loginFail = ({ message }) => {
  refreshVerification();
  verificationState.visible = false;
  verificationState.loading = false;
  formState.loading = false;
  antMessage.error(message);
};
const loginWithSmsCode = (response) => {
  verificationState.tryCount = 0;
  verificationState.token = response.authToken;
  verificationState.text = `<b>${response.phone1} ${response.phone1} ${response.phone2}</b> numaralı cep telefonunuza gönderilen onay kodunu giriniz.`;
  verificationState.visible = true;
};
const loginWithMailCode = (response) => {
  refreshVerification();
  verificationState.tryCount = 0;
  verificationState.token = response.authToken;
  verificationState.text = `<b>${response.mail1}****@${response.mail2}</b> adresine gönderilen onay kodunu giriniz.`;
  verificationState.visible = true;
};

const loginWithGoogle2FA = (response) => {
  verificationState.isGoogle2FA = true;
  verificationState.tryCount = 0;
  verificationState.token = response.google2FAToken;
  verificationState.text =
    "Google Authenticator uygulamasından gelen 6 haneli kodu giriniz.";
  verificationState.visible = true;
};

const fastRegister = (response) => {
  window.open(
    "https://www.itemsatis.com/" + response.FastRegisterURL,
    "_blank"
  );
};

const loginWithRecaptchaV2 = () => {
  formState.pending = true;
  resolveRecaptchaToken();
};

const loginWithItemSatis = async ({
  token,
  postData = `UserName=${formState.username}&Password=${encodeURIComponent(
    formState.password
  )}`,
  recaptchaVersion = "v3",
}) => {
  formState.loading = true;
  const params =
    postData +
    `&g-recaptcha-response=${token}&fcmToken=${authState.value.fcmToken}&recaptchaVersion=${recaptchaVersion}`;
  const response = await https.post("/Login", params, {
    withCredentials: true,
  });
  formState.loading = false;
  if (!!!response || !!!response.data)
    return loginFail({
      message:
        "İsteğinizi geçici olarak gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz",
    });
  if (response.data.success) return loginSuccess(response.data);
  if (response.data.smsonay) return loginWithSmsCode(response.data);
  if (response.data.mailonay) return loginWithMailCode(response.data);
  if (response.data.google2FA) return loginWithGoogle2FA(response.data);
  if (response.data.FastRegisterURL) return fastRegister(response.data);
  if (response.data.recaptchaVerifyFailed)
    return loginWithRecaptchaV2(response.data);
  loginFail({ message: response.data.message || "Bir hata oluştu" });
};

const sendVerificationCode = async ({ code, isTrust }) => {
  if (!isTrust) return;
  verificationState.loading = true;
  let data = `OnayKodu=${code}&authToken=${verificationState.token}`;
  if (verificationState.isGoogle2FA) {
    data = `Google2FACode=${code}&authToken=${verificationState.token}&OnayKodu=1`;
  }

  const response = await https.post("/Login", data, { withCredentials: true });
  verificationState.loading = false;
  if (response && response.data && response.data.success)
    return loginSuccess(response.data);
  if (verificationState.tryCount < 2) {
    verificationState.tryCount++;
    refreshVerification();
    antMessage.error(
      `Doğrulama kodu yanlış. Kalan hak: ${3 - verificationState.tryCount}`,
      2
    );
    return;
  }
  verificationState.visible = false;
  loginFail({
    message:
      response.data.message ||
      "Doğrulama kodu yanlış. Lütfen tekrar deneyiniz.",
  });
};

const getPrivacyContent = async () => {
  const formData = new FormData();
  formData.append("Id", 40)
  const response = await https.post("/getPageDetail", formData);
  privacyContent.value = response.data.content;
  console.log(response);
};

const loginWithSecure = async ({ postData = undefined }) => {
  const token = await tryRecaptcha();
  if (!token) return;
  loginWithItemSatis({ token });
};

const tryRecaptcha = async () => {
  if (!authLoginForm.value) return null;
  return authLoginForm.value.getRecaptchaToken();
};

const resolveRecaptchaToken = () => {
  if (!authLoginForm.value) return;
  return authLoginForm.value.resolveRecaptchaToken();
};

const onCaptchaVerify = (token) => {
  if (!formState.pending) return;
  formState.pending = false;
  loginWithItemSatis({
    token: token,
    recaptchaVersion: "v2",
  });
};

const onCancelModal = () => {
  refreshVerification();
  verificationState.visible = false;
  verificationState.code = null;
  formState.pending = false;
  formState.loading = false;
  formState.username = "";
  formState.password = "";
};

onMounted(() => {
  getPrivacyContent();
})
</script>

<template>
  <section class="login-view-section">
    <auth-login-form
      ref="authLoginForm"
      v-model:username="formState.username"
      v-model:password="formState.password"
      v-model:captcha-visible="formState.captchaVisible"
      :privacy-content="privacyContent"
      :loading="formState.loading"
      @is:captcha-verify="onCaptchaVerify"
      @is:finish="loginWithSecure({})"
    ></auth-login-form>
    <template v-if="verificationState.visible">
      <!-- For performance -->
      <is-verification-modal
        ref="verificationModal"
        :visible="true"
        v-model:loading="verificationState.loading"
        :title="verificationState.title"
        :text="verificationState.text"
        @is:ok="sendVerificationCode"
        @is:cancel="onCancelModal"
      ></is-verification-modal>
    </template>
  </section>
</template>
