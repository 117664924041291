<script setup>
import { VueRecaptcha } from "vue-recaptcha";
import { computed, ref } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useApi } from "@/config/api";

const recaptcha = useReCaptcha();
const api = useApi();

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:visible", "is:verify", "is:expired"]);

const recaptchaComponent = ref();

const visible = computed({
  get: () => props.visible,
  set: (v) => emit("update:visible", v),
});

const executeToken = async (action) => {
  try {
    await recaptcha.recaptchaLoaded();
    return await recaptcha.executeRecaptcha(action);
  } catch (e) {
    return null;
  }
};

const resolveToken = () => {
  if (recaptchaComponent.value && recaptchaComponent.value.execute) {
    recaptchaComponent.value.execute();
  }
};

const onVerify = (token) => {
  emit("is:verify", token);
  visible.value = false;
};

const onExpired = () => {};

defineExpose({
  executeToken,
  resolveToken,
});
</script>

<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="Robot Doğrulaması"
      :footer="false"
    >
      <a-row>
        <a-col :span="24">
          <p class="is-description">
            Lütfen aşağıdaki alandan robot doğrulaması yapınız
          </p>
        </a-col>
        <a-col :span="24" class="mt-2">
          <vue-recaptcha
            ref="recaptchaComponent"
            :sitekey="api.recaptchaV2.siteKey"
            @verify="onVerify"
            @expired="onExpired"
          ></vue-recaptcha>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<style lang="scss">
.recaptcha-col {
  display: flex;
  justify-content: center;
}
</style>
